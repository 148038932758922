<template>
    <section>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general v-loading="loading" :mostrar-buscador="true" :data="tableData" :columnas="dataColumnas" :columnas-formateadas="formaterColumns" class="prueba" sin-datos="No tienes clientes deudores">
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="nombre" label="Cliente" sortable width="230" class-name="f-16 f-600">
                            <template slot-scope="scope">
                                <p class="cr-pointer" @click="verTienda(scope.row.id_user)">
                                    {{ scope.row.nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor" label="Deuda" sortable min-width="100" class-name="f-16">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_pendiente) }}
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column prop="fecha_menor_cuota" label="Próximo pago" sortable min-width="100" class-name="f-16">
                            <template slot-scope="scope">
                                <span v-if="scope.row.vencido" class="text-danger">{{ scope.row.fecha_menor_cuota }}</span>
                                <span v-else>{{ scope.row.fecha_menor_cuota }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="" width="80">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="light" content="Hablar con el cliente" placement="right-end">
                                    <div class="btn-edit rounded-circle br-12 d-middle-center cr-pointer" style="width:32px;height:32px;" @click="AbrirChat(scope.row.created_by_pedido)">
                                        <i class="icon-message-text-outline f-20" />
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <modalChat ref="modalChat" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'

export default {
    data(){
        return {
            loading: false,
            dataColumnas: [
                'nombre','valor_pendiente',
                { valor: 'ultimo_pago', titulo:"Último pago" },
                { valor: 'ultimo_pedido', titulo:"Último pedido" }
            ],
            formaterColumns: {
                ultimo_pago: { valor: 'ultimo_pago', fecha: true },
                ultimo_pedido: { valor: 'ultimo_pedido', fecha: true },
                fecha_menor_cuota: { valor: 'fecha_menor_cuota', fecha: true },

            },
            tableData: [
                // { nombre: 'OXXO', total: '$ 534.000', ultimo_pago: '01 Jul. 2018', ultimo_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ],
        }
    },

    mounted(){
        this.listarTabla()
    },
    methods: {
        async listarTabla(){
            try {
                this.loading = true
                const {data} = await Creditos.listarCreditosTabClientes()
                this.tableData = data.creditos
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        AbrirChat(created_by_pedido){
            this.$refs.modalChat.toggle(created_by_pedido, 6, false, 1) // 6 = tipo credito pedido -admin // utilizo el 1 solo para listar info cliente modal
        },
        verTienda(id_user){
            this.$router.push({name: 'tendero-vip.creditos.cliente.ver', params: { id_user: id_user }})
        }
    }
}
</script>

<style lang="css" scoped>
</style>
